
import {computed, defineComponent, ref} from "vue";
import {GetMetricFormName, LoadPanel, PinaFilterObjects} from "@/core/composite/composite";
import {useMetricValueStore} from "@/store/metric.valute.store";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import MetricValueUpdateForm from "@/views/metric/MetricValueUpdateForm.vue";
import Swal from "sweetalert2";
import MetricValueService from "@/core/services/MetricValueService";
import {useMetricStore} from "@/store/metric.store";
import MetricValueFormat from "@/views/metric/value/MetricValueFormat.vue";
import UserLink from "@/views/user/UserLink.vue";

export default defineComponent({
  name: "MetricValues",
  components: {
    UserLink,
    MetricValueFormat,
    MetricValueUpdateForm, BaseModal, QuickAction, DateTimeFormat, KtDatatable
  },
  props: {
    size: {type: Number, default: 10},
    metricId: {type: String},
    teamMetric: {default: false},
  },
  setup(props) {
    const state = ref({id: '', value: {amount: ''}})
    const filterObject = ref({metricId: props.metricId})
    const store = useMetricValueStore();
    const metricStore = useMetricStore();
    const page = computed(() => store.pageSecond);
    const metric = computed(() => metricStore.entity);

    const headers = ref<any>([])
    const buildHeader = () => {

      headers.value = [{
        name: 'Date',
        key: 'date',
        sortable: false
      },
        {
          name: 'Assigned',
          key: 'assigned',
          sortable: false
        },
        {
          name: 'Value',
          key: 'value',
          sortable: false
        },
        {
          name: '',
          key: 'actions',
          sortable: false
        }]

    }
    buildHeader()
    return {
      state,
      headers,
      page,
      filterObject,
      ...LoadPanel(),
      ...GetMetricFormName(),
      metric,
      ...PinaFilterObjects(store, filterObject.value, ['reportBy'], '', true, props.size)
    }
  },
  emits: ['updateValue'],
  methods: {
    update(row) {
      this.state.id = row.id;
      this.state.value.amount = row.amount;
      const base = this.$refs.metricUpdateValueRef as typeof BaseModal;
      base.showBaseModal();
    },
    onSave() {
      const base = this.$refs.metricUpdateValueRef as typeof BaseModal;
      base.hideBaseModal();
      this.paginationDataLoad();
      this.$emit('updateValue')
    },
    deleteValue(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await MetricValueService.delete(id).then(() => {
            this.paginationDataLoad()
          })
        }
      })
    },
    sendEmail(value) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      MetricValueService.sendEmail(value.id).then(() => {
      }).finally(() => {
        Swal.close();
      })
    }
  },
  watch: {
    metricId(cb) {
      this.filterObject.metricId = cb;
      this.updateFilterObject(this.filterObject);
    }
  }
})
