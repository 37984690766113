import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_HighChart = _resolveComponent("HighChart")!

  return (_ctx.state.loading)
    ? (_openBlock(), _createBlock(_component_EntityLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (!_ctx.state.loading && _ctx.state.data)
          ? (_openBlock(), _createBlock(_component_HighChart, {
              key: 0,
              chart: _ctx.state.data,
              name: _ctx.name
            }, null, 8, ["chart", "name"]))
          : _createCommentVNode("", true)
      ], 64))
}