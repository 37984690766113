
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "BaseCheckBox",
  props: {
    id: {},
    name: {type: String, required: false},
    modelValue: {},
    size: {type: String, default: 'regular'},
  },
  emits: ['update:modelValue'],
  setup(props) {
    const model = ref(props.modelValue);
    const clazz = ref('form-check form-check-custom form-check-solid')
    const buildClazz = (size) => {
      if (size === 'sm') {
        clazz.value = 'form-check form-check-custom form-check-solid form-check-sm';
      }
      if (size === 'lg') {
        clazz.value = 'form-check form-check-custom form-check-solid form-check-lg';
      }
    }
    watch(() => props.modelValue, (cb) => {
      model.value = cb
    })
    watch(() => props.size, (cb) => {
      buildClazz(cb);
    })
    buildClazz(props.size);
    return {
      buildClazz,
      clazz,
      model,
    }
  },
  methods: {
    onChange() {
      this.$emit('update:modelValue', this.model);
    }
  }
})
