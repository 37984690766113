
import {defineComponent, ref, watch} from "vue";
import DocService from "@/core/services/DocService";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "DocSelect",
  components: {BaseSelect},
  props: {
    modelValue: {required: true},
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const model = ref(props.modelValue);
    const menu = ref<any>([]);
    const loadMenu = () => {
      DocService.menu().then(res => {
        menu.value = res;
      })
    }

    loadMenu();
    watch(() => props.modelValue, cb => {
      model.value = cb;
    })
    const selected = (id) => {
      emit('update:modelValue', id)
    }
    return {
      selected,
      model,
      menu,
    }
  }
})
