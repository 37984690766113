import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-wrap py-3 fs-6 fw-bold"
}
const _hoisted_3 = {
  key: 0,
  class: "flex-grow-1 text-gray-400"
}
const _hoisted_4 = { class: "flex text-gray-800" }
const _hoisted_5 = {
  key: 1,
  class: "d-flex flex-wrap py-3 fs-6 fw-bold"
}
const _hoisted_6 = {
  key: 0,
  class: "flex-grow-1 text-gray-400"
}
const _hoisted_7 = { class: "flex text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserLink = _resolveComponent("UserLink")!
  const _component_DateTimeFormat = _resolveComponent("DateTimeFormat")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.row, (item, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col",
        key: idx
      }, [
        (item.hideIfNull)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (item.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (item.name)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item.name) + ": ", 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_4, [
                      (item.slot)
                        ? _renderSlot(_ctx.$slots, item.key, { key: 0 })
                        : (item.type==='USER')
                          ? (_openBlock(), _createBlock(_component_UserLink, {
                              key: 1,
                              user: item.value
                            }, null, 8, ["user"]))
                          : (item.type ==='DATE')
                            ? (_openBlock(), _createBlock(_component_DateTimeFormat, {
                                key: 2,
                                date: item.value
                              }, null, 8, ["date"]))
                            : (item.router)
                              ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 3,
                                  to: item.router,
                                  target: item.target
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.value), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to", "target"]))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                  _createTextVNode(_toDisplayString(item.value), 1)
                                ], 64))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (item.name)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(item.name) + ": ", 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                (item.slot)
                  ? _renderSlot(_ctx.$slots, item.key, { key: 0 })
                  : (item.type==='USER')
                    ? (_openBlock(), _createBlock(_component_UserLink, {
                        key: 1,
                        user: item.value
                      }, null, 8, ["user"]))
                    : (item.type ==='DATE')
                      ? (_openBlock(), _createBlock(_component_DateTimeFormat, {
                          key: 2,
                          date: item.value
                        }, null, 8, ["date"]))
                      : (item.type ==='DATETIME')
                        ? (_openBlock(), _createBlock(_component_DateTimeFormat, {
                            key: 3,
                            date: item.value,
                            time: true
                          }, null, 8, ["date"]))
                        : (item.router)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 4,
                              to: item.router,
                              target: item.target
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.value), 1)
                              ]),
                              _: 2
                            }, 1032, ["to", "target"]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                              _createTextVNode(_toDisplayString(item.value), 1)
                            ], 64))
              ])
            ]))
      ]))
    }), 128))
  ]))
}