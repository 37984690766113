
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "MetricValueStatus",
  props:{
    code: {type:String, required:true}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const buildLabel = (code: string) => {
      console.log(code)
      switch (code) {
        case 'MISSING':
          data.value = {name: 'Missing', clazz: 'danger'}
          break
        case 'READY':
          data.value = {name: 'Ready', clazz: 'success'}
          break
        case 'NA':
          data.value = {name: 'NA', clazz: 'secondary'}
          break
      }
    }
    buildLabel(props.code);
    watch(() => props.code, (cb) => {
      buildLabel(cb);
    })
    return {
      data
    }
  }
})
