import {defineStore} from "pinia";
import {useLoadingStore} from "@/store/loading";
import MetricService from "@/core/services/MetricService";

export const useMetricStore = defineStore('useMetricStore', {
  state: () => ({
    page: {
      data: [],
      total: 0,
      totalPages: 0,
    },
    entity: {},
  }),
  actions: {
    list(filter: any) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true)
      MetricService.findAll<any>(filter).then(res => {
        this.page = res
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    },
    get(id: any) {
      MetricService.get<any>(id).then(res => {
        this.entity = res
      })
    },
    setEntity(entity) {
      this.entity = entity
    }
  }
})