
import {defineComponent} from "vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import UserLink from "@/views/user/UserLink.vue";

export default defineComponent({
  name: "Row",
  components: {UserLink, DateTimeFormat},
  props: {
    row: {type: Array, required: true}
  }
})
