import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-dark fw-bolder" }
const _hoisted_2 = {
  key: 1,
  class: "float-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValueFormat = _resolveComponent("ValueFormat")!
  const _component_MetricValueStatus = _resolveComponent("MetricValueStatus")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.value.status ==='READY')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.value.hasBreakdown)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "link-primary cursor-pointer hover",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadPanel(_ctx.value.id, 'METRIC_VALUE')))
              }, [
                _createVNode(_component_ValueFormat, {
                  type: _ctx.type,
                  value: _ctx.value.amount
                }, null, 8, ["type", "value"])
              ]))
            : (_openBlock(), _createBlock(_component_ValueFormat, {
                key: 1,
                type: _ctx.type,
                value: _ctx.value.amount
              }, null, 8, ["type", "value"]))
        ], 64))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_MetricValueStatus, {
            code: _ctx.value.status
          }, null, 8, ["code"])
        ]))
  ]))
}