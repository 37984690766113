
import {computed, defineComponent, ref, watch} from "vue";
import BaseForm from "../../components/base/form/BaseForm.vue";
import * as Yup from "yup";
import {BreakDownSelect, MetricGroupsSelect, TeamSelect, UserSelect} from "@/core/composite/composite";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import BaseCheckBox from "@/components/base/checkbox/BaseCheckBox.vue";
import MetricService from "@/core/services/MetricService";
import UserListSelect from "@/components/common/UserListSelect.vue";
import store from "@/store";
import DocSelect from "@/views/doc/DocSelect.vue";

export default defineComponent({
  name: "MetricForm",
  components: {DocSelect, UserListSelect, BaseCheckBox, BaseRadio, BaseSelect, FormErrorMessage, BaseForm, Field},
  props: {
    data: {type: Object, required: true},
    id: {type: String},
  },
  emits: ['save','cancel'],
  setup(props) {
    const user = computed(() => store.getters.currentUser);
    const submitting = ref(false);
    const validator = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      groupIdRequest: Yup.string().required().label("Metric Group"),
      description: Yup.string().length(1000)
    });
    const model = ref(props.data)
    watch(() => props.data, (cb) => {
      model.value = cb;
      if (cb.frequency.period === 'WEEKLY') {
        buildWeekly(cb.frequency.frequencies)
      }
      if (cb.frequency.period === 'MONTHLY') {
        buildMonthly(cb.frequency.frequencies)
      }
    })
    const monthly = ref('');
    const weekly = ref({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    })

    const buildWeekly = (frequencies: any[]) => {
      weekly.value = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }
      frequencies.forEach(item => {
        if (item == 1) {
          weekly.value.monday = true
        }
        if (item == 2) {
          weekly.value.tuesday = true
        }
        if (item == 3) {
          weekly.value.wednesday = true
        }
        if (item == 4) {
          weekly.value.thursday = true
        }
        if (item == 5) {
          weekly.value.friday = true
        }
        if (item == 6) {
          weekly.value.saturday = true
        }
        if (item == 7) {
          weekly.value.sunday = true
        }
      })
    }
    const buildMonthly = (frequencies: any[]) => {
      monthly.value = frequencies.join(",");
    }
    return {
      user,
      submitting,
      validator,
      ...UserSelect(),
      model,
      weekly,
      monthly,
      ...MetricGroupsSelect(),
      ...BreakDownSelect(),
      ...TeamSelect(),
    }
  },

  methods: {
    onDiscard() {
      this.model.type ='NUMBER';
      this.model.frequency.period = 'DAILY'
      this.model.frequency.frequencies = []
      this.model.assignType = 'USER'
      this.model.name = '';
      this.model.requestAssigneeId = ''
      this.model.teamIdRequest = ''
      this.model.groupIdRequest = '';
      this.model.breakdownIdRequest = '';
      this.model.direction = 'UP';
      this.model.description = '';
      this.model.docIdRequest = '';
    },
    onSave() {
      if (this.model.frequency.period === 'WEEKLY') {
        this.model.frequency.frequencies = []
        if (this.weekly.monday) {
          this.model.frequency.frequencies.push(1)
        }
        if (this.weekly.tuesday) {
          this.model.frequency.frequencies.push(2)
        }
        if (this.weekly.wednesday) {
          this.model.frequency.frequencies.push(3)
        }
        if (this.weekly.thursday) {
          this.model.frequency.frequencies.push(4)
        }
        if (this.weekly.friday) {
          this.model.frequency.frequencies.push(5)
        }
        if (this.weekly.saturday) {
          this.model.frequency.frequencies.push(6)
        }
        if (this.weekly.sunday) {
          this.model.frequency.frequencies.push(7)
        }
      }
      if (this.model.frequency.period === 'MONTHLY') {
        this.model.frequency.frequencies = []
        this.model.frequency.frequencies = this.monthly.split(",").map(item => Number(item));
        if (this.model.frequency.frequencies[0] === 0) {
          this.model.frequency.frequencies = []
        }
      }
      this.submitting = true
      if (this.id) {
        MetricService.update(this.id, this.model).then(() => {
          this.$emit('save')
        }).finally(() => {
          this.submitting = false
        })
      } else {
        MetricService.create(this.model).then(() => {
          this.$emit('save')
        }).finally(() => {
          this.submitting = false;
        })
      }
    }
  }
})
