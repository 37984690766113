
import {defineComponent, ref, watch} from "vue";
import DocService from "@/core/services/DocService";
import {GetSpaceId} from "@/core/composite/composite";

export default defineComponent({
  name: "DocLink",
  props: {
    id: {}
  },
  setup(props) {
    const doc = ref<any>({});
    const loadDoc = (id) => {
      if (id) {
        try {
          DocService.getLink(id).then(res => {
            doc.value = res;
          })
        } catch (ex) {
          doc.value = {}
        }
      } else {
        doc.value = {};
      }
    }
    watch(() => props.id, cb => {
      loadDoc(cb)
    })
    loadDoc(props.id);
    return {
      doc,
      ...GetSpaceId(),
    }
  }
})
