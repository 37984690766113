import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex mt-3" }
const _hoisted_2 = ["name", "value"]
const _hoisted_3 = { class: "form-check-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return (_openBlock(), _createElementBlock("label", {
        class: "form-check form-check-sm form-check-custom me-5",
        key: index
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          name: _ctx.name,
          class: "form-check-input",
          value: item.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChangeCheckInput && _ctx.onChangeCheckInput(...args)))
        }, null, 40, _hoisted_2), [
          [_vModelRadio, _ctx.model]
        ]),
        _createElementVNode("span", _hoisted_3, [
          (_ctx.custom)
            ? _renderSlot(_ctx.$slots, item.value, { key: 0 })
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(item.name), 1)
              ], 64))
        ])
      ]))
    }), 128))
  ]))
}