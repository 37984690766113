
import {defineComponent} from "vue";
import ValueFormat from "@/views/metric/value/ValueFormat.vue";
import {LoadPanel} from "@/core/composite/composite";
import MetricValueStatus from "@/views/metric/MetricValueStatus.vue";

export default defineComponent({
  name: "MetricValueFormat",
  components: {MetricValueStatus, ValueFormat},
  props: {
    type: {},
    value: {}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
