import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["label"]
const _hoisted_2 = { disabled: "" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_select = _resolveComponent("base-select")!

  return (_openBlock(), _createBlock(_component_base_select, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    name: "docId",
    data: _ctx.menu,
    "no-select": true,
    onOnSelected: _ctx.selected
  }, {
    default: _withCtx(({option: collection}) => [
      _createElementVNode("optgroup", {
        label: collection.name
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(collection.children, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.id
          }, [
            (item.type ==='FOLDER')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("option", _hoisted_2, " " + _toDisplayString(item.name), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: child.id
                    }, [
                      (child.type === 'PAGE')
                        ? (_openBlock(), _createElementBlock("option", {
                            key: 0,
                            value: child.id
                          }, "   " + _toDisplayString(child.name), 9, _hoisted_3))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ], 64))
              : _createCommentVNode("", true),
            (item.type === 'PAGE')
              ? (_openBlock(), _createElementBlock("option", {
                  key: 1,
                  value: item.id
                }, _toDisplayString(item.name), 9, _hoisted_4))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["modelValue", "data", "onOnSelected"]))
}