
import {defineComponent} from "vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";

export default defineComponent({
  name: "ValueFormat",
  components: {CurrencyFormat},
  props: {
    type: {},
    value: {},
    isFloatEnd: {default: true}
  },
})
