<template>
  <vue-highcharts
    type="chart" v-if="chartOptions"
    :options="chartOptions"
    :redrawOnUpdate="true"
    :oneToOneUpdate="false"
    :animateOnUpdate="true"
    @rendered="onRender"
    @update="onUpdate"
    @destroy="onDestroy"/>
</template>

<script>
import VueHighcharts from 'vue3-highcharts';
import {ref, watch} from 'vue';

export default {
  name: "HighChart",
  components: {
    VueHighcharts
  },
  props: {
    name: {
      required: true,
    },

    chart: {
      required: true,
    }
  },
  setup(props) {
    const chartOptions = ref();
    watch(() => props.chart, (chart) => {
      build(chart, props.name);
    });
    watch(() => props.name, (val) => {
      build(props.chart, val);
    });

    const build = function (chart, name) {
      chartOptions.value = {
        chart: {
          type: 'column',
        },
        title: {
          text: name,
        },
        xAxis: {
          type: 'category',
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y}'
            }
          }
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        series: [{
          name: chart?.options?.name,
          data: chart?.options?.series,
        }],
      }
    };
    build(props.chart, props.name);
    const onRender = () => {
      // console.log('Chart rendered');
    };
    const onUpdate = () => {
      // console.log('Chart updated');
    };

    const onDestroy = () => {
      // console.log('Chart destroyed');
    };

    return {
      onRender,
      onUpdate,
      onDestroy,
      chartOptions
    }
  },
  mounted() {

  }

}
</script>

<style scoped>

</style>
