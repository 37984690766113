
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import MetricValueService from "@/core/services/MetricValueService";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {useMetricValueStore} from "@/store/metric.valute.store";
import MetricUnit from "@/views/metric/MetricUnit.vue";
import DocLink from "@/views/doc/DocLink.vue";

export default defineComponent({
  name: "MetricValueUpdateForm",
  components: {DocLink, MetricUnit, BaseRadio, FormErrorMessage, BaseForm, Field},
  props: {
    id: {type: String},
    data: {type: Object},
  },
  emits: ['save'],
  setup(props) {
    const model = ref<any>({})
    const submitting = ref(false);
    const metricValueStore = useMetricValueStore();
    const loadModel = (id) => {
      MetricValueService.getModel(id).then(res => {
        model.value = res;
        metricValueStore.setFormMetricName(res.metricName)
      })
    }
    const validate = ref({})
    if (props.id) {
      loadModel(props.id)
    }
    watch(() => props.id, cb => {
      loadModel(cb);
    })
    return {
      validate,
      loadModel,
      model,
      submitting,
    }
  },
  methods: {
    onSave() {
      this.submitting = true
      MetricValueService.updateValue(this.model).then(() => {
        this.$emit('save')
      }).finally(() => {
        this.submitting = false
      })
    },
    onChange() {
      if (this.model.type !== 'YES_NO') {
        this.model.amount = this.model.items.map(a => Number(a.amount)).reduce(function (a, b) {
          return a + b;
        })
        console.log(this.model.amount)
      }
    }
  }
})
