
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "CurrencyFormat",
  props: {
    amount: {type: Number},
    currency: {type: String, default: 'USD'},
    isFloatEnd: {default: true}
  },
  setup(props) {
    const buildFormat = (currency: string) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    }
    const format = ref('');
    const buildDisplay = (amount, currency) => {
      const formatter = buildFormat(currency);
      format.value = amount !== null && amount !== undefined ? formatter.format(amount) : '';
    }
    buildDisplay(props.amount, props.currency);
    watch(() => props.amount, (cb) => {
      buildDisplay(cb, props.currency);
    })

    watch(() => props.currency, (cb) => {
      buildDisplay(props.amount, cb);
    })

    return {
      format,
    }
  }
})
