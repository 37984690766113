
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {GetSpaceId, LoadPanel, PinaLoadEntity} from "@/core/composite/composite";
import {useMetricStore} from "@/store/metric.store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import MetricStatus from "@/views/metric/MetricStatus.vue";
import MetricValues from "@/views/metric/MetricValues.vue";
import Row from "@/components/base/common/Row.vue";
import MetricChart from "@/views/metric/MetricChart.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import MetricService from "@/core/services/MetricService";
import {makeDownloadLink} from "@/core/helpers/functions";
import ValueFormat from "@/views/metric/value/ValueFormat.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import MetricForm from "@/views/metric/MetricForm.vue";
import UserLink from "@/views/user/UserLink.vue";

export default defineComponent({
  name: "Metric",
  components: {
    UserLink,
    MetricForm,
    BaseModal,
    ValueFormat,
    QuickAction,
    MetricChart,
    Row,
    MetricValues,
    MetricStatus
  },
  setup() {
    const state = ref({
        name: 'Create Metric',
        metric: {
          name: '',
          type: 'NUMBER',
          groupIdRequest: '',
          requestAssigneeId: '',
          breakdownIdRequest: '',
          teamIdRequest: '',
          assignType: 'USER',
          frequency: {period: 'DAILY', frequencies: []},
          direction: 'UP',
          description: '',
          docIdRequest: '',
        },
        metricId: ''
      }
    )
    const store = useMetricStore();
    const metric = computed<any>(() => store.entity)
    const spaceId = GetSpaceId().spaceId
    onMounted(() => {
      if (metric.value.id) {
        setCurrentPageBreadcrumbsWithParams("Metric",
          [
            {
              link: true,
              router: `/` + spaceId.value + '/metric/all',
              text: 'All Metrics'
            },
            {link: false, router: '', text: metric.value.name}
          ]
        )
      }
    });
    watch(metric, cb => {
      setCurrentPageBreadcrumbsWithParams("Metric",
        [
          {link: true, router: `/` + spaceId.value + '/metric/all', text: 'All Metrics'},
          {link: false, router: '', text: cb.name}
        ]
      )
    })
    return {
      ...GetSpaceId(),
      metric,
      state,
      ...LoadPanel(),
      ...PinaLoadEntity(store)
    }
  },
  methods: {
    deleteMissing(id) {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await MetricService.deleteMissing(id).then(() => {
            const value = this.$refs.metricValueRef as typeof MetricValues
            value.paginationDataLoad()
          })
        }
      })
    },
    onUpdatedValue() {
      const chart = this.$refs.metricChartRef as typeof MetricChart
      chart.loadData(this.metric.id)
      this.loadEntity();
    },
    onExport() {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      MetricService.export(this.metric.id).then(res => {
        makeDownloadLink(res)
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
    onEdit(entity) {
      this.state.metric = {
        name: entity.name,
        type: entity.type,
        groupIdRequest: entity?.group?.id,
        requestAssigneeId: entity?.assignee?.id,
        breakdownIdRequest: entity?.breakdown?.id,
        assignType: entity.team?.id ? 'TEAM' : 'USER',
        teamIdRequest: entity?.team?.id,
        frequency: entity.frequency,
        direction: entity.direction,
        description: entity.description,
        docIdRequest: entity.docId,
      }
      this.state.metricId = entity.id;
      this.state.name = 'Edit Metric'
      const modal = this.$refs.formMetricRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSave() {
      this.reloadEntityWithId(this.metric.id)
      const modal = this.$refs.formMetricRef as typeof BaseModal;
      modal.hideBaseModal();
    },

    onStart(metric) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      MetricService.start(metric.id).then(() => {
        this.reloadEntityWithId(metric.id)
      }).finally(() => {
        Swal.close();
      })
    },
    onStop(metric) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      MetricService.stop(metric.id).then(() => {
        this.reloadEntityWithId(metric.id)
      }).finally(() => {
        Swal.close();
      })
    },
    onComplete(metric) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      MetricService.complete(metric.id).then(() => {
        this.reloadEntityWithId(metric.id)
      }).finally(() => {
        Swal.close();
      })
    },
    onDeleteEntity(metric) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await MetricService.delete(metric.id).then(() => {
            this.$router.push(`/` + this.spaceId + "/all-metrics");
          })
        }
      })
    },
  },

})
