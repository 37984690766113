
import {defineComponent, reactive, ref, watch} from "vue";
import GroupService from "@/core/services/GroupService";

export default defineComponent({
  name: "UserListSelect",
  props: {
    clazz: {type: String, default: () => 'form-control form-control-solid form-control-lg fw-bold'},
    modelValue: {},
    showCompany: {type: Boolean, default: () => true},
    showInactive: {tye: Boolean, default: () => false},
    allowEmpty: {type: Boolean, default: () => true},
    teams: {type: Array, default: () => []},
    groupId: {type: String, required: true},
    multiple: {type: Boolean, default: () => false}
  },
  setup(props) {

    const options = ref<any>([])
    const loading = ref(false);
    const state = reactive<any>({
      show: false,
      selectObjects: []
    })
    const loadOptions = (groupId) => {
      loading.value = true
      GroupService.getUsers(groupId).then(res => {
        options.value = res;
        loadSelectedItem();
      }).finally(() => {
        loading.value = false;
      })
    }
    loadOptions(props.groupId)
    watch(() => props.groupId, cb => {
      loadOptions(cb)
    })
    watch(() => props.modelValue, (cb: any) => {
      if (cb != undefined) {
        if (cb.length === 0) {
          state.selectObjects = []
        } else {
          if (!props.multiple) {
            const user = options.value.find(item => item['id'] === cb)
            if (user) {
              state.selectObjects = [user];
            }
          } else {
            cb.forEach(i => {
              const user = options.value.find(item => item['id'] === i)
              if (user && !state.selectObjects.includes(user)) {
                state.selectObjects.push(user);
              }
            })
          }
        }
      } else {
        state.selectObjects = []
      }

    })
    const loadSelectedItem = () => {
      const modelValue = ref<any>(props.modelValue);
      if (!modelValue.value || modelValue.value.length === 0) {
        state.selectObjects = []
      } else {
        if (!props.multiple) {
          const user = options.value.find(item => item['id'] === modelValue.value)
          if (user) {
            state.selectObjects = [user];
          }
        } else {
          modelValue.value.forEach(i => {
            const user = options.value.find(item => item['id'] === i)
            if (!state.selectObjects.includes(user)) {
              state.selectObjects.push(user);
            }
          })
        }
      }
    }
    return {
      state,
      loading,
      options,
    }
  },
  emits: ['update:modelValue', 'onSelect'],
  methods: {
    onClose() {
      this.state.show = false
    },
    remove(item) {
      this.state.selectObjects = this.state.selectObjects.filter(val => val !== item)
      if (!this.multiple) {
        this.$emit('update:modelValue', "")
        this.$emit('onSelect', '')
      }
    },
    clear() {
      this.$emit('update:modelValue', "")
      this.state.selectObjects = [];
      this.$emit('onSelect', '')
    },
    onSelect(item) {
      if (this.state.selectObjects.includes(item)) {
        return
      } else {
        if (this.multiple) {
          this.state.selectObjects.push(item)
        } else {
          this.state.show = false
          this.state.selectObjects = [item]
        }
      }
      const ids = this.state.selectObjects.map(value => value.id)
      this.$emit('update:modelValue', this.multiple ? ids : ids[0])
      this.$emit('onSelect', this.multiple ? ids : ids[0])
    }
  }
})
