
import {defineComponent, ref, watch} from "vue";
import MetricService from "@/core/services/MetricService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import HighChart from "@/components/base/chart/HighChart.vue";

export default defineComponent({
  name: "MetricChart",
  components: {HighChart, EntityLoading},
  props: {
    name: {type: String, default: ()=>''},
    metricId: {type: String, required: true}
  },
  setup(props) {
    const state = ref({loading: true, data: {}})

    const loadData = (id) => {
      state.value.loading = false
      MetricService.chart(id).then(res => {
        state.value.data = res;
      }).finally(() => {
        state.value.loading = false
      })
    }
    loadData(props.metricId)
    watch(()=> props.metricId , (cb)=>{
      loadData(cb);
    })
    return {
      loadData,
      state,
    }
  }
})
